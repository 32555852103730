//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

$breakpoints-down: 'md', 'lg', 'xl';

.table {
  table-layout: fixed;
  width: 100%;

  td, th {
    padding: 16px;
  }

  th {
    padding-top: 0;
  }

  thead {
    border-bottom: 1px solid $black;

    th {
      font-weight: $font-weight-normal;
      padding-bottom: 12px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $gray-200;
    }

    td {
      font-size: 14px;
    }

    label {
      font-size: 14px;
      font-weight: $font-weight-bold;
      margin-bottom: 4px;
    }
  }

  thead th,
  tbody td {
    &:first-of-type, {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

@each $breakpoint in $breakpoints-down {
  .#{$breakpoint} {
    @include media-breakpoint-down($breakpoint) {
      thead {
        display: none;
      }

      tbody {
        &,
        tr,
        td {
          display: block;
        }

        tr {
          margin-left: -8px;
          margin-right: -8px;
          padding-bottom: 16px;
          margin-bottom: 16px;
        }

        td {
          &,
          &:first-of-type,
          &:last-of-type {
            padding: 8px;
          }
        }
      }
    }

    @include media-breakpoint-up($breakpoint) {
      label {
        display: none;
      }
    }
  }

  .#{$breakpoint}Inline {
    @include media-breakpoint-down($breakpoint) {
      tbody {
        td {
          display: inline-block;
          max-width: 100%;
        }
      }
    }
  }
}
