$btn-font-weight: 700;
$btn-line-height: 1.1;

$btn-padding-y: 4px;
$btn-padding-x: 16px;
$btn-font-size: map-get($font-sizes, 16);
$btn-border-radius: 10px;

$btn-padding-y-sm: 4px;
$btn-padding-x-sm: 16px;
$btn-font-size-sm: map-get($font-sizes, 14);
$btn-border-radius-sm: 10px;

$btn-padding-y-lg: 4px;
$btn-padding-x-lg: 16px;
$btn-font-size-lg: map-get($font-sizes, 16);
$btn-border-radius-lg: 10px;

$carousel-custom-dots-indicator-default-size: 6px;
$carousel-custom-dots-indicator-active-size: 6px;
$carousel-custom-indicator-default-bg-color: $primary;
$carousel-custom-indicator-active-bg-color: #fff;
$carousel-indicator-spacer: 4px;

$form-label-margin-bottom: 10px;
$form-label-font-size: 1.2rem;
$form-label-font-weight: $font-weight-bold;
$form-label-color: $text-primary;

$input-height: 40px;
$input-height-sm: 32px;
$input-height-lg: 46px;

$input-padding-y: 4px;
$input-padding-x: 16px;
$input-font-size: 1.4rem;

$input-padding-y-lg: 4px;
$input-padding-x-lg: 16px;
$input-font-size-lg: 1.6rem;

$input-padding-y-sm: 4px;
$input-padding-x-sm: 16px;
$input-font-size-sm: 1.4rem;

$input-font-family: $font-family-sans-serif;
$input-font-weight: $font-weight-normal;
$input-line-height: calc-line-height(20, 14);
$input-border-radius: 10px;

$input-bg: transparent;
$input-color: $text-primary;
$input-border-color: $gray-600;
$input-border-width: 1px;

$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: 16px;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: $input-border-color;

$input-solid-bg: $gray-100;
$input-solid-bg-focus: $gray-200;
$input-solid-placeholder-color: rgba(23, 32, 71, 0.7);
$input-solid-color: $text-primary;

$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-select-indicator-color: $gray-900;
$form-select-indicator-padding: 16px * 3;
$form-select-feedback-icon-padding-end: 16px;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;

$form-check-input-bg: $input-solid-bg;
$form-check-input-width: 1.6rem;
$form-check-label-color: $text-primary;
$form-check-input-border-radius: 4px;

$form-check-input-bg-solid: $input-solid-bg;

$form-switch-color: #fff;
$form-switch-width: 6rem;
$form-switch-height: 2.6rem;

$spinner-width: 18px;
$spinner-height: $spinner-width;
$spinner-border-width: 3px;
$spinner-animation-speed: 0.65s;

$spinner-width-sm: 14px;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 2px;

$spinner-width-lg: 32px;
$spinner-height-lg: $spinner-width-lg;
$spinner-border-width-lg: 3px;

$alert-padding-y: 8px;
$alert-padding-x: 18px;
$alert-margin-bottom: 24px;
$alert-border-radius: 10px;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: 1px;

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1.7rem;
$nav-link-font-size: 1.4rem;
$nav-link-font-weight: 700;
$nav-link-color: rgba(23, 32, 71, 0.7);
$nav-link-hover-color: rgba(23, 32, 71, 0.7);
$nav-tabs-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: rgba(23, 32, 71, 0.7);
$nav-tabs-border-radius: 0;

$pagination-color: $body-color;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-border-radius: 0;
$pagination-margin-start: 4px;
$pagination-border-color: transparent;

$pagination-active-color: #fff;
$pagination-active-bg: $primary;
$pagination-active-border-color: $pagination-border-color;

$pagination-hover-color: $pagination-active-color;
$pagination-hover-bg: $pagination-active-bg;
$pagination-hover-border-color: $pagination-active-border-color;

$pagination-focus-color: $pagination-color;
$pagination-focus-bg: $pagination-bg;
$pagination-focus-box-shadow: none;
$pagination-focus-outline: 1px solid $pagination-active-bg;

$pagination-disabled-color: rgba(23, 32, 71, 0.2);
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-border-color: $pagination-border-color;

$modal-backdrop-bg: #172047;
$modal-backdrop-opacity: 0.7;

$modal-inner-padding: 0;

$modal-content-bg: #fff;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 20px;
$modal-content-box-shadow-xs: 0px 6px 50px 5px rgba(79, 79, 176, 0.05);
$modal-content-box-shadow-sm-up: 0px 6px 50px 5px rgba(79, 79, 176, 0.05);

$modal-sm: 300px;
$modal-md: 520px;
$modal-lg: 800px;
$modal-xl: 1140px;

$progress-height: 0.6rem;
$progress-border-radius: 100px;
