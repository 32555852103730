//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.wrapper {
  width: 32px !important;
  height: 32px !important;
}

.menuWrapper {
  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -5px) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: white;
    content: '';
  }
}
