#kt_content {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 80%;

  @include media-breakpoint-down(xxl) {
    background-image: none !important;
  }

  @include media-breakpoint-up(xxl) {
    background-size: 100% 50%;
  }

  &.fixed-icon {
    margin-bottom: 48px;
  }
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

button {
  border: none;
  box-shadow: none;
  background: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* stylelint-disable-next-line */
input[type=number] {
  appearance: textfield;
}
/*! purgecss end ignore */
