.notification-animation {
  animation: notification 0.2s ease;
}

@keyframes notification {
  0% {
    opacity: 0;
    transform: translate3d(0, 16px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
