.form-control {
  min-height: $input-height;
}

.form-control-sm {
  min-height: $input-height-sm;
}

.form-control-lg {
  min-height: $input-height-lg;
}
