.draggable-source--is-dragging {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.draggable-mirror {
  background-color: white;
  border: 1px dashed gainsboro;
  z-index: 99999;
}

.draggable-container--is-dragging {
  .text-editor-wrapper {
    pointer-events: none;
  }
  .draggable-source.draggable--original {
    pointer-events: auto;
  }
}

.BlockGenerator .Block {
  @include media-breakpoint-down('xl') {
    width: 100% !important;
  }
}