@include media-breakpoint-up(lg) {
  .wrapper {
    .aside-enabled.aside-fixed & {
      padding-left: calc(#{get($aside-config, width)} + #{get($content-spacing, desktop)});
    }

    .aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
      padding-left: calc(
        #{get($aside-config, minimized-width)} + #{get($content-spacing, desktop)}
      );
    }
  }
}
