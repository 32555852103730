.progress {
  background: linear-gradient(
    251.85deg,
    rgba(150, 114, 194, 0.25) 28.12%,
    rgba(62, 81, 229, 0.25) 79.31%
  );

  .progress-bar {
    background: linear-gradient(84.46deg, #3e51e5 78.81%, #ba3ee5 111.4%);
    border-radius: 100px;
  }
}
