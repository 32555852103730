$datepicker__background-color: $primary;
$datepicker__border-color: $gray-100;
$datepicker__selected-color: $primary;
$datepicker__text-color: $text-primary;
$datepicker__header-color: #fff;
$datepicker__border-radius: 20px;
$datepicker__day-margin: 2px;
$datepicker__font-size: 13px;
$datepicker__font-family: 'Red Hat Display', sans-serif;
$datepicker__item-size: 28px;
$datepicker__margin: 12px;
$datepicker__navigation-size: 6px;
$datepicker__triangle-size: 0;

@import '../../../../../../../node_modules/react-datepicker/src/stylesheets/datepicker';

.react-datepicker-wrapper {
  display: block;
}
.react-datepicker-popper {
  z-index: 999;
}
.react-datepicker__month-text:hover {
  color: #fff;
}

.react-datepicker {
  box-shadow: 0 6px 50px 5px rgba(79, 79, 176, 0.05);
}

.react-datepicker__navigation-icon {
  font-size: 14px;

  &::before {
    border-color: #fff;
  }
}

.react-datepicker__day-name {
  color: #fff;
}

.react-datepicker__day--selected {
  font-weight: $font-weight-bold;
}

.react-datepicker__day--keyboard-selected {
  background-color: $primary-light;
}

.react-datepicker__day {
  &:hover {
    color: #fff;
    font-weight: $font-weight-bold;
  }

  &.react-datepicker__day--in-range {
    &:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
      background-color: $gray-300;
      border-radius: 0;
      color: $text-primary;
    }
  }

  &.react-datepicker__day--range-start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.react-datepicker__day--range-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background-color: $gray-600;
  color: $text-primary;
}

.react-datepicker__day--disabled {
  &,
  &:hover {
    font-weight: $font-weight-normal;
    color: $gray-600;
  }
}

.react-datepicker__month {
  .react-datepicker__month-text {
    width: 74px;
    min-height: 32px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
