.input-group-text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
}

.input-group-text-solid {
  background-color: $input-solid-bg !important;
  border-color: transparent !important;
  color: rgba(23, 32, 71, 0.2);
}

.input-group {
  .form-control:not(.form-control-solid) {
    &.is-invalid {
      border-color: $input-border-color;
    }
  }
}

textarea.form-control {
  height: 215px;
}
