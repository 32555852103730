.form-select {
  min-height: $input-height;
}

.form-select-sm {
  min-height: $input-height-sm;
}

.form-select-lg {
  min-height: $input-height-lg;
}
