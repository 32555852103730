.aside-menu {
  .menu-item {
    font-style: get($font-sizes, 14);

    .menu-link {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .menu {
    > .menu-item {
      > .menu-link {
        .menu-title {
          font-weight: $font-weight-bold;
        }

        .menu-icon {
          @include svg-icon-size(16px);

          margin-right: 8px;
        }
      }
    }
  }

  .menu-sub {
    > .menu-item {
      > .menu-link {
        padding-left: 54px !important;

        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .menu-link.is-off {
    opacity: 0.4 !important;
  }
}

.aside-dark {
  .menu {
    .menu-item {
      $color: #fff;
      $color-icon: rgba($color, 0.7);
      $color-action: theme-active-color('white');
      $color-icon-action: $color-action;

      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state($color, $color-icon, $color, $color, transparent);
      @include menu-link-hover-state(
        $color-action,
        $color-icon-action,
        $color-action,
        $color-action,
        transparent
      );
      @include menu-link-show-state(
        $color-action,
        $color-icon-action,
        $color-action,
        $color-action,
        transparent
      );
      @include menu-link-active-state(
        $color-action,
        $color-icon-action,
        $color-action,
        $color-action,
        transparent
      );
    }
  }
}

.menu-user {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 60px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 8px solid #fff;
  }

  .menu-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .menu-title {
    color: $gray-900 !important;
  }
}
