//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.card {
  background-color: #fff;
  box-shadow: 0 6px 50px 5px rgba(79, 79, 176, 0.05);
  border-radius: 20px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: rgba(62, 81, 229, 0.1);
  border-radius: 8px;
  margin-right: 25px;
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 24px;
  }
}

.description {
  color: rgba(23, 32, 71, 0.4);
}
