@import '../../init';

.nav-tabs {
  overflow-x: auto !important;
  flex-wrap: nowrap;

  .nav-item {
    .nav-link {
      height: 100%;
      min-width: 110px;
      color: rgba(23, 32, 71, 0.7);
      background-color: #fff;
      margin: 0 1px;
      text-align: center;
      border: none;
      .svg-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      background: linear-gradient(
        251.85deg,
        rgba(150, 114, 194, 0.25) 28.12%,
        rgba(62, 81, 229, 0.25) 79.31%
      );
      border: none;
    }

    &:first-child {
      .nav-link {
        border-radius: 30px 0 0 30px;
      }
    }

    &:last-child {
      .nav-link {
        border-radius: 0 30px 30px 0;
      }
    }
  }
}

.nav-tabs-border {
  .nav-item {
    .nav-link {
      margin: 0;
      border: 1px solid rgba(62, 81, 229, 0.1);
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      border: 1px solid rgba(62, 81, 229, 0.1);
    }

    &:first-child {
      .nav-link {
        border-right: none;
      }
    }

    &:last-child {
      .nav-link {
        border-left: none;
      }
    }
  }
}

.nav-tabs-long {
  .nav-item {
    .nav-link {
      @include media-breakpoint-up(xl) {
        min-width: 180px;
      }
    }
  }
}
