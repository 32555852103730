.stepper.steps {
  .stepper-nav {
    .stepper-item {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &::before {
        @include media-breakpoint-up(sm) {
          position: absolute;
          content: '';
          top: 19px;
          right: -27px;
          width: calc(100% - 32px);
          height: 2px;
          background: linear-gradient(
            251.85deg,
            rgba(150, 114, 194, 0.25) 28.12%,
            rgba(62, 81, 229, 0.25) 79.31%
          );
          border-radius: 100px;
        }
      }

      &:last-child::before {
        display: none;
      }

      .stepper-content {
        min-width: 60px;
        min-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
          min-width: 65px;
          min-height: 65px;
        }

        .stepper-icon {
          margin-top: 6px;
          display: flex;
          position: relative;
          width: 28px;
          height: 28px;
          justify-content: center;
          align-items: center;
          background: linear-gradient(
            251.85deg,
            rgba(150, 114, 194, 0.25) 28.12%,
            rgba(62, 81, 229, 0.25) 79.31%
          );
          border-radius: 50%;
          transition: all 0.1s ease;

          svg {
            transition: all 0.1s ease;
            width: 12px;
            height: 12px;
          }
        }
      }

      &.current,
      &.completed {
        .stepper-icon {
          margin-top: 0;
          width: 40px;
          height: 40px;
          background: linear-gradient(84.46deg, #3e51e5 78.81%, #ba3ee5 111.4%);

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      &.current::before {
        @include media-breakpoint-up(sm) {
          right: -26px;
          width: calc(100% - 40px);
        }
      }

      &.completed::before {
        @include media-breakpoint-up(sm) {
          background: linear-gradient(84.46deg, #3e51e5 78.81%, #ba3ee5 111.4%);
          right: -20px;
          width: calc(100% - 46px);
        }
      }
    }
  }
}
