.tox-notifications-container {
  display: none;
}

.tox .tox-statusbar {
  display: none !important;
}

.tox-editor-container {
  border-radius: 10px 0px 10px 10px;
}

.tox-tinymce {
  border: none !important;
}
