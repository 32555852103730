$spacers: (
  0: 0,
  2: 2px,
  4: 4px,
  5: 5px,
  8: 8px,
  10: 10px,
  12: 12px,
  14: 14px,
  16: 16px,
  18: 18px,
  20: 20px,
  24: 24px,
  26: 26px,
  28: 28px,
  32: 32px,
  36: 36px,
  40: 40px,
  52: 52px,
  48: 48px,
  64: 64px,
  80: 80px,
  96: 96px,
  160: 160px,
);

$custom-sizes: (
  full: 100%,
  auto: auto,
  0: 0,
  4: 4px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
  18: 18px,
  20: 20px,
  24: 24px,
  26: 26px,
  28: 28px,
  32: 32px,
  40: 40px,
  48: 48px,
  50: 50px,
  58: 58px,
  64: 64px,
  80: 80px,
  200: 200px,
  280: 280px,
  320: 320px,
);

$symbol-sizes: (
  24: 24px,
  35: 35px,
  default: 50px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
);

$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-row-columns: 6;

$gutters: $spacers;

$border-radius-sm: 8px;
$border-radius: 10px;
$border-radius-lg: 20px;

$border-width: 1px;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
);
