.pagination {
  align-items: center;

  .page-item {
    .page-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      padding: 8px;
      border-radius: 50%;
      font-size: get($font-sizes, 14);
      line-height: get($line-heights, f14);
    }

    .page-action {
      font-weight: $font-weight-bold;
      font-size: get($font-sizes, 14);
      line-height: get($line-heights, f14);
    }

    &.active {
      .page-link {
        font-weight: $font-weight-bold;
      }
    }

    &.disabled {
      .page-action {
        color: $pagination-disabled-color;
        pointer-events: none;
      }
    }
  }
}
