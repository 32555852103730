.campaign-range {
  position: relative;

  .rc-slider-handle {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 22' width='6' height='22' ><path stroke='rgb(0,0,0)' stroke-width='.2' d='M3.1 0v22'/><rect width='5.5' height='9.5' x='.25' y='6.25' fill='rgb(255,255,255)' stroke='rgb(0,0,0)' stroke-width='.5' rx='2.75'/><path fill='rgb(0,0,0)' d='M2 9h2v1H2zM2 12h2v1H2z'/></svg>") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 11px !important;
    height: 24px !important;
    background-color: #fff !important;
    margin-top: -9px !important;
    border: none !important;
    padding: 2px !important;
  }

  .rc-slider-handle.rc-slider-handle-dragging {
    border: none !important;
    box-shadow: none !important;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    top: calc(50% - 11px);
    width: 1px;
    height: 22px;
    background-color: $gray-300;
  }

  &::before {
    left: -2px;
  }

  &::after {
    right: -2px;
  }

  .rc-slider-handle-3 {
    visibility: hidden !important;
    pointer-events: none !important;
  }
}
