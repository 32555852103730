//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.bg {
  @include media-breakpoint-down(xl) {
    background: none !important;
  }

  @include media-breakpoint-up(xl) {
    background-repeat: no-repeat;
    background-size: 50% 100%;
  }
}

.hero,
.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  @include media-breakpoint-up(xl) {
    min-height: 100vh;
    padding: 12px;
  }
}

.hero {
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-up(xl) {
    background: none !important;
  }
}

.slide {
  padding: 16px 32px 0;

  @include media-breakpoint-up(xxl) {
    padding: 16px 128px 0;
  }
}

.slideImage {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }

  @include media-breakpoint-up(xl) {
    height: 360px;
  }

  @include media-breakpoint-up(xxxl) {
    height: 520px;
  }
}

.slideTitle {
  color: #fff;
  font-size: map-get($font-sizes, 32);
  line-height: map-get($line-heights, f32);
  font-weight: $font-weight-bolder;

  @include media-breakpoint-up(xxxl) {
    font-size: map-get($font-sizes, 50);
    line-height: map-get($line-heights, f50);
  }
}

.slideDescription {
  font-size: map-get($font-sizes, 18);
  line-height: map-get($line-heights, f18);
  min-height: 68px;

  @include media-breakpoint-up(xxxl) {
    font-size: map-get($font-sizes, 27);
    line-height: map-get($line-heights, f27);
    min-height: 80px;
  }
}
