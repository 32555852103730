.custom-uppy {
  .uppy-DragDrop-container {
    background-color: #fff;
    border: 2px dashed $gray-300;

    .uppy-DragDrop-label,
    .uppy-DragDrop-note {
      font-family: $font-family-sans-serif;
      font-size: get($font-sizes, 16);
      line-height: get($line-heights, f16);
    }

    .uppy-DragDrop-label {
      font-weight: $font-weight-bold;
      color: $primary;
    }

    .uppy-DragDrop-note {
      color: $gray-900;
    }

    .uppy-DragDrop-inner {
      &::before {
        height: 80px;
        content: url('../../../../../assets/asort/uploadicon.png');
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      svg {
        display: none;
      }
    }
  }

  .uppy-Dashboard-inner {
    background-color: #fff;
    border: 2px dashed $gray-300;
  }

  .uppy-Dashboard-innerWrap {
    > .uppy-DashboardContent-bar {
      display: none;
      pointer-events: none;
    }
  }

  .uppy-Dashboard-files {
    padding: 0;
    margin: 0;

    > div {
      height: auto !important;
    }
  }

  .uppy-Dashboard-Item {
    position: relative;
    height: 160px;
    margin: 0;
    border: none;
    padding: 10px;

    .uppy-Dashboard-Item-fileInfo {
      display: none;
      pointer-events: none;
    }

    .uppy-Dashboard-Item-fileInfoAndButtons {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .uppy-Dashboard-Item-action--remove,
    .uppy-Dashboard-Item-action--edit {
      position: absolute;
      width: 32px;
      height: 32px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 5px;
    }

    .uppy-Dashboard-Item-action--remove {
      top: 5px;
    }

    .uppy-Dashboard-Item-action--edit {
      bottom: 5px;
    }

    .uppy-Dashboard-Item-preview {
      border-radius: $border-radius;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .uppy-size--md {
    .uppy-Dashboard-Item {
      width: 25%;
      height: 140px;
    }
  }

  .uppy-size--lg {
    .uppy-Dashboard-Item {
      width: 20%;
      height: 140px;
    }
  }

  [data-uppy-paneltype="FileEditor"] {
    .uppy-DashboardContent-bar {
      height: 0;
      background: transparent;
    }

    .uppy-DashboardContent-title {
      display: none;
    }

    .uppy-DashboardContent-back,
    .uppy-DashboardContent-save {
      margin-top: 40px;
      background: #fff;
      padding: 8px 12px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }

    .uppy-DashboardContent-back {
      background-color: $danger;
      color: #fff;
    }

    .uppy-DashboardContent-save {
      background-color: $primary;
      color: #fff;
    }
  }

  .uppy-FileInput-container {
    margin-bottom: 0;
  }

  .uppy-FileInput-btn {
    font-size: map-get($font-sizes, 12);
    border: none;
    background: none;
    text-decoration: underline;
    padding: 0;

    &,
    &:hover {
      color: $primary;
    }
  }
}

.custom-uppy--single {
  .uppy-Dashboard-Item {
    width: 100% !important;
    height: 355px !important;
  }
}
