.aside {
  .aside-footer {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 16px;
  }
}

@include media-breakpoint-up(lg) {
  .aside {
    @include border-radius(get($aside-config, border-radius));

    .aside-logo,
    .aside-footer {
      padding-left: get($aside-config, padding-x);
      padding-right: get($aside-config, padding-x);
    }

    .aside-footer {
      position: absolute;
      bottom: 16px;
      left: 0;
      margin-bottom: 0;
    }

    .aside-fixed & {
      position: fixed;
      top: get($content-spacing, desktop);
      bottom: get($content-spacing, desktop);
      left: get($content-spacing, desktop);
      z-index: get($aside-config, z-index);
      overflow: visible;
    }

    &.aside-dark {
      background-color: get($aside-config, bg-color);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .aside-toggle {
      position: absolute;
      right: -8px;
      bottom: 24px;
      width: 16px;
      height: 16px;
      background-color: #fff;
    }

    .logo-minimize {
      display: none;
    }

    .aside-footer {
      .user-name {
        opacity: 1;
        transition: opacity get($aside-config, transition-speed) ease;
        transition-delay: 0.1s;
      }
    }

    .aside-menu {
      .menu-content,
      .menu-title {
        transition: opacity get($aside-config, transition-speed) ease 0.2s;
      }
    }

    [data-kt-aside-minimize='on'] & {
      // Not hovered mode
      &:not(.aside-hoverable),
      &:not(:hover) {
        .aside-footer {
          padding-left: 16px;
          padding-right: 16px;

          .user-name {
            opacity: 0;
            transition: none;
            transition-delay: 0s;
          }
        }
      }

      .aside-menu {
        .menu-link {
          &:hover {
            .menu-icon {
              svg {
                path {
                  fill: #fff !important;
                  fill-opacity: 1 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
