.image-gallery-slides {
  border-radius: 24px;
  overflow: hidden;
}

.image-gallery-slide {
  height: 407px;
  border-radius: 24px;
  overflow: hidden;
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.image-gallery-thumbnail {
  width: 150px !important;
  min-width: 150px;
  height: 85px;
  border: 4px solid transparent !important;
  border-radius: 20px;
  overflow: hidden;
  opacity: 0.5;
  transition: opacity 0.25s linear !important;
  span {
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &.active {
    opacity: 1;
  }
}

.image-gallery-thumbnails-container {
  display: flex;
}
