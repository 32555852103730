//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.item {
  margin-bottom: 10px;
  background: #f5f6fd;
  padding: 10px 20px !important;
  border-radius: 6px;
}

.icon {
  transform: rotate(0);
  transition: 0.25s linear;
}
.iconActive {
  transform: rotateX(180deg);
}
