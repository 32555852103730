@import 'variables.colors';

@function calc-line-height($line-height, $font-size) {
  @return ($line-height * 0.1) / ($font-size * 0.1);
}

$font-family-sans-serif: 'Red Hat Display', sans-serif;

$font-size-base: 1.4rem;
$line-height-base: 1.4285714286;

$h1-font-size: $font-size-base;
$h2-font-size: $font-size-base;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$paragraph-margin-bottom: 0;

$font-sizes: (
  9: 0.9rem,
  12: 1.2rem,
  14: 1.4rem,
  16: 1.6rem,
  18: 1.8rem,
  23: 2.3rem,
  25: 2.5rem,
  27: 2.7rem,
  32: 3.2rem,
  40: 4rem,
  50: 5rem,
  55: 5.5rem,
);

$line-heights: (
  1: 1,
  f12: calc-line-height(17, 12),
  f14: calc-line-height(20, 14),
  f16: calc-line-height(25, 16),
  f18: calc-line-height(30, 18),
  f25: calc-line-height(33, 25),
  f27: calc-line-height(35, 27),
  f40: calc-line-height(52, 40),
  f50: calc-line-height(66, 50),
  f55: calc-line-height(73, 55),
);

$headings-margin-bottom: 0;
$headings-font-family: $font-family-sans-serif;
$headings-font-style: null;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-color: inherit;
