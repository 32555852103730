.btn {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(.btn-icon) {
    min-width: 120px;
    min-height: $input-height;

    &.btn-sm {
      min-height: $input-height-sm;
    }

    &.btn-lg {
      min-height: $input-height-lg;
    }
  }

  &-primary {
    background: linear-gradient(84.46deg, #3e51e5 78.81%, #ba3ee5 111.4%);

    &:hover {
      background: $primary;
    }
  }

  &-secondary {
    background: linear-gradient(
      251.85deg,
      rgba(150, 114, 194, 0.25) 28.12%,
      rgba(62, 81, 229, 0.25) 79.31%
    );
  }

  &-success {
    background: #06b98e;
  }

  &-outline{
    background-color: transparent;
    border: 1px solid $gray-200;
  }

  &-outline-light {
    background-color: transparent;
    color: $white;
    border: 1px solid $white !important;
  }

  &-quick-help {
    position: fixed;
    bottom: 12px;
    right: 12px;

    &::after {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px #0000001a;
      animation-fill-mode: forwards;
      transition: none;
      animation: pulse-animation 2s infinite;
    }

    @include media-breakpoint-up(lg) {
      bottom: 40px;
      right: 40px;
    }
  }

  &-custom-dark {
    background-color: #192133;

    &,
    &:hover {
      color: #fff;
    }
  }

  &-red {
    background-color: #fd506f;
    font-size: 12px;
    color: #fff;
    font-weight: 900;
    padding: 12px !important;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
