.wrapper-max {
  width: 100%;
  max-width: 2048px;
  margin-left: auto;
  margin-right: auto;
}

.list-none {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-first-upper:first-letter {
  text-transform: uppercase;
}

.bg-gradient-primary {
  background: linear-gradient(84.46deg, #3e51e5 78.81%, #ba3ee5 111.4%);
}

.hide-input {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  white-space: nowrap;
  width: 1px;
}

$utilities: map-merge(
  $utilities,
  (
    'position': (
      property: position,
      responsive: true,
      values: static relative absolute fixed sticky,
    ),
    'overflow': (
      property: overflow,
      responsive: true,
      values: auto hidden visible scroll,
    ),
    'opacity': (
      property: opacity,
      class: opacity,
      values: $opacity-values,
    ),
    'opacity-hover': (
      property: opacity,
      state: hover,
      class: opacity,
      values: $opacity-values,
    ),
    'font-size': (
      rfs: false,
      responsive: true,
      property: font-size,
      class: fs,
      values: $font-sizes,
    ),
    'width': (
      responsive: true,
      property: width,
      class: w,
      values: $custom-sizes,
    ),
    'max-width': (
      responsive: true,
      property: max-width,
      class: mw,
      values: $custom-sizes,
    ),
    'min-width': (
      responsive: true,
      property: min-width,
      class: min-w,
      values: $custom-sizes,
    ),
    'height': (
      responsive: true,
      property: height,
      class: h,
      values: $custom-sizes,
    ),
    'max-height': (
      responsive: true,
      property: max-height,
      class: mh,
      values: $custom-sizes,
    ),
    'min-height': (
      responsive: true,
      property: min-height,
      class: min-h,
      values: $custom-sizes,
    ),
    'z-index': (
      property: z-index,
      class: z-index,
      values: $zindex-values,
    ),
    'rounded': (
      property: border-radius,
      class: rounded,
      values: (
        null: $border-radius,
        0: 0,
        sm: $border-radius-sm,
        lg: $border-radius-lg,
        circle: 50%,
        pill: $border-radius-pill,
      ),
    ),
    "border-color": (
      property: border-color,
      class: border,
      values: map-merge($theme-colors, $grays)
    ),
    'border-top-width': (
      property: border-top-width,
      class: border-top,
      values: $border-widths,
    ),
    'border-bottom-width': (
      property: border-bottom-width,
      class: border-bottom,
      values: $border-widths,
    ),
    'border-right-width': (
      property: border-right-width,
      class: border-right,
      values: $border-widths,
    ),
    'border-left-width': (
      property: border-left-width,
      class: border-left,
      values: $border-widths,
    ),
    'border-top-right-radius': (
      property: border-top-right-radius,
      class: border-top-right-radius,
      values: (
        0: 0,
      ),
    ),
    'border-bottom-right-radius': (
      property: border-bottom-right-radius,
      class: border-bottom-right-radius,
      values: (
        0: 0,
      ),
    ),
    'line-height': (
      property: line-height,
      class: lh,
      responsive: true,
      values:
        map-merge(
          (
            0: 0,
            1: 1,
            sm: $line-height-sm,
            base: $line-height-base,
            lg: $line-height-lg,
            xl: $line-height-xl,
            xxl: $line-height-xxl,
          ),
          $line-heights
        ),
    ),
    'letter-spacing': (
      property: letter-spacing,
      class: ls,
      values: $letter-spacing-values,
    ),
    'background-color': (
      property: background-color,
      class: bg,
      values: map-merge($theme-colors, $grays),
    ),
    'fill': (
      property: fill,
      class: fill,
      values: map-merge($theme-colors, $grays),
    ),
  )
);
