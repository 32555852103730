//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.wrapper {
  position: fixed;
  z-index: 2000;
  width: 100%;
  max-width: 260px;
  top: 16px;
  right: 16px;

  @include media-breakpoint-up(md) {
    max-width: 320px;
  }
}
