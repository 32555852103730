$white: #fff;
$black: #000;

$gray-100: #3e51e50d;
$gray-200: #3e51e51A;
$gray-300: #17204726;
$gray-400: #A2A6B566;
$gray-500: if(isDarkMode(), #565674, #a1a5b7) !default;
$gray-600: #17204733;
$gray-700: #a2a6b5;
$gray-800: if(isDarkMode(), #cdcdde, #3f4254) !default;
$gray-900: #172047b3;

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

$text-primary: #172047;

$body-color: $text-primary;
$border-color: $text-primary;

$white-active: darken($white, 25%);
$white-light: lighten($white, 10%);
$white-inverse: $white;
$white-dark: $white-active;

$dark: $gray-900;
$dark-active: darken($dark, 10%);
$dark-light: lighten($dark, 10%);
$dark-inverse: $white;
$dark-dark: $dark-active;

$primary: #3e51e5;
$primary-active: #5e67ee;
$primary-light: #5e67ee;
$primary-inverse: $white;
$primary-dark: $primary-active;

$success: #06b98e;
$success-active: darken($success, 10%);
$success-light: #e5f5ef;
$success-inverse: $white;
$success-dark: $success-active;

$info: #3fa2f7;
$info-active: rgba(23, 32, 71, 0.7);
$info-light: #edf7ff;
$info-inverse: $white;
$info-dark: $info-active;

$warning: #fbc121;
$warning-active: darken($warning, 20%);
$warning-light: #fdf4dc;
$warning-inverse: $white;
$warning-dark: $warning-active;

$danger: #ff5a55;
$danger-active: darken($danger, 10%);
$danger-light: #ffe0df;
$danger-inverse: $white;
$danger-dark: $danger-active;

$theme-dark-colors: (
  'white': $white-dark,
  'primary': $primary-dark,
  'dark': $dark-dark,
  'success': $success-dark,
  'info': $info-dark,
  'warning': $warning-dark,
  'danger': $danger-dark,
);

$box-shadow-xs: 0 0.1rem 0.75rem 0.25rem rgba($black, 0.05) !default; // Custom variable
$box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba($black, 0.05) !default;
$box-shadow: 0 6px 50px 5px rgba(79, 79, 176, 0.05);
$box-shadow-lg: 0 1rem 2rem 1rem rgba($black, 0.1) !default;
