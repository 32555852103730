//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.wrapper {
  display: inline-block;
  box-shadow: 0px 6px 30px 5px rgba(47, 47, 77, 0.12), 0px 8px 10px -5px rgba(118, 123, 176, 0.2);
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 460px;
}

.wrapperContent {
  background-color: #fff;
  padding: 32px 16px;

  @include media-breakpoint-up(md) {
    padding: 32px 48px;
  }

  @include media-breakpoint-up(xxxl) {
    padding: 48px;
  }
}

.wrapperSocial {
  background: radial-gradient(
    258.21% 400.15% at 72.28% 7.14%,
    #172047 0%,
    #625e99 37.65%,
    #bfacff 100%
  );
  padding: 24px 16px;

  @include media-breakpoint-up(md) {
    padding: 24px 48px;
  }

  @include media-breakpoint-up(xxxl) {
    padding: 32px 48px;
  }
}

.social {
  width: 35px;
  height: 35px;
  border: 1px solid $primary;
  border-radius: 50%;
  margin: 0 4px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
